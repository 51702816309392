import './video-modal.scss';

class VideoModal {
    constructor(element, options) {
        const settings = {
            initAttr: 'data-section'
        };

        this.settings = Object.assign({}, settings, options);
        this.$videoModal = element;
        this.$closeButton = this.$videoModal.querySelector(
            '.video-modal__close-button'
        );
        this.$videoContainer = this.$videoModal.querySelector(
            '.video-modal__video-container'
        );

        this.initialize();
    }

    initialize() {
        this.$closeButton.addEventListener('click', () => {
            this.clearVideoContainer();
            this.closeModal();
        });
    }

    loadVideo(video) {
        this.clearVideoContainer();

        const { type, path } = JSON.parse(video);

        if (type === 'youtube') {
            const iframe = document.createElement('iframe');
            iframe.id = 'ytplayer';
            iframe.type = 'text/html';
            iframe.frameBorder = 0;

            const playerParams = new URLSearchParams({
                autoplay: 0,
                muted: 0
            }).toString();

            iframe.src = `https://www.youtube-nocookie.com/embed/${path}?${playerParams}`;

            this.$videoContainer.appendChild(iframe);
        }
    }

    clearVideoContainer() {
        this.$videoContainer.innerHTML = '';
    }

    openModal() {
        this.$videoModal.classList.toggle('is--open', true);
    }

    closeModal() {
        this.$videoModal.classList.toggle('is--open', false);
    }
}

export { VideoModal };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $videoModal = $context.querySelector('[data-video-modal="root"]');
        $videoModal.API = new VideoModal($videoModal);

        const $videoElements = $context.querySelectorAll('[data-video]');
        [].slice.call($videoElements).forEach(($videoElement) => {
            $videoElement.addEventListener('click', () => {
                $videoModal.API.loadVideo($videoElement.dataset.video);
                $videoModal.API.openModal();
            });
        });
    }
});
